import { ChangeDetectorRef,Renderer2,Component, Inject, OnInit } from '@angular/core';
import { Plan } from '../components/plan/model/plan.model';
import { ThemeService } from '../theme.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagesService } from '../services/image/images.service';
import { PlanService } from '../services/plan/plan.service';
import { WebService } from '../services/web.service';
import { NotificationService } from '../services/notification.service';
import { LoaderService } from '../services/loader.service';
import { FacPopupComponent } from '../components/fac-popup/fac-popup.component';
import { Router, ActivatedRoute } from '@angular/router';
import { interval, Observable, of, timer,Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { catchError, switchMap,takeWhile  } from 'rxjs/operators';
import { UniqueCodeService } from './unique-code.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppDialogContainerComponent } from '../checkout/app-dialog-container/app-dialog-container.component'; // Importa tu componente aquí
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { formatDate } from '@angular/common';



@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  
  isAutoFilling: boolean = false; // Nueva bandera para ignorar cambios automáticos

    isManualInput: boolean = true;
    public selectedPlan: Plan;
    planImage: any;
    description: any;
    feeCost_plan: any;
    plan: any;
    form: any;
    addressID: any;
    tdc: string;
    hasPlanID: any;
    isActive = true;
    // NECESARIOS PARA 3DS DINAMICO
    retryCount = 0;
    maxRetries = 5;
    uniqueCode: string = "";
    myIp: string = '189.127.166.102';
    myHeight: number;
    myWidth: number;
    jwtTokenForThird: string;
    transactionIdForThird: string;
    transactionId: string;
    iframeValue: string;
    iframeName: string;
    iframeUrl: string;
    redirectUrl: string;
    dataObject: any;
    private pollingSuccess = new Subject<void>();
    iframeElement: HTMLIFrameElement;
    chp: any;
    suscription: any;
    namepartner: any;
    urlimagedatail: string
    startDateIsValid : boolean

  // Almacenamiento interno de la fecha de hoy
  todayDate: string;
  calculatedDate: Date;
  private openLoader(): void {
    console.log('Opening loader...'); // Ejemplo de registro adicional
    this.loader.open();
  }
  
  
  private closeLoader(): void {
    console.log('Closing loader...${reason}'); // Ejemplo de registro adicional
    this.loader.close();
  }
  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private uniqueCodeService: UniqueCodeService,
    @Inject(DOCUMENT) private document: Document,private activateRoute: ActivatedRoute, private router: Router, public themeService: ThemeService, //public loginService: LoginService,
     private sanitizer: DomSanitizer, private dialog: MatDialog,// public auth: AuthService,
     private imageService: ImagesService, private planService: PlanService, private web: WebService, private notification: NotificationService, private loader: LoaderService) {
      this.initializeForm();
  }
    // SETUP PARA 3DS DINAMICO
    private initializeForm(): void {
      this.form = new FormGroup({
        nameHolderCreditCard: new FormControl('', [Validators.required]),
        numberCreditCard: new FormControl('', [Validators.required, Validators.pattern(/^[0-9-]*$/), this.validateCreditCardNumber]),
        ccv: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]),
        expMonthCreditCard: new FormControl('', [Validators.required]),
        expYearCreditCard: new FormControl('', [Validators.required]),
        street: new FormControl('', [Validators.required]),
        city: new FormControl('', [Validators.required]),
        postalCode: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
        phone: new FormControl('', [Validators.required])
      });
    }
    // , Validators.pattern(/^\d{7,10}$/)
    // 3DS DINAMICO
    ngAfterViewInit(): void {
      setTimeout(() => {
        const uniqueCode = this.generateUniqueCode();
        this.uniqueCodeService.setUniqueCode(uniqueCode);
        this.uniqueCode = uniqueCode;
        this.addUniqueCodeToHead(uniqueCode);
        this.addUniqueCodeToBody(uniqueCode);
        this.cdr.detectChanges();
      }, 0);
    }
    formatCreditCardNumber(): void {
      if (!this.isManualInput) return; // Salir si no es entrada manual
    
      const control = this.form.get('numberCreditCard');
      let value = control.value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos
    
      if (value.length > 16) {
        value = value.substring(0, 16); // Limitar a 16 caracteres
      }
    
      const formattedValue = value.match(/.{1,4}/g)?.join('-') || value;
      control.setValue(formattedValue, { emitEvent: false });
    }
    validateCreditCardNumber = (control: FormControl): { [key: string]: any } | null => {
      const value = control.value.replace(/-/g, ''); // Eliminar guiones para validación
    
      if (!value || !this.isManualInput) return null; // No aplicar validación si no es entrada manual
    
      // Comprobar si tiene exactamente 16 dígitos
      if (value.length !== 16) {
        return { invalidLength: true };
      }
    
      // Validar usando el Algoritmo de Luhn
      if (!this.luhnCheck(value)) {
        return { invalidCardNumber: true };
      }
    
      return null;
    };
    // Algoritmo de Luhn para validar el número de tarjeta
    luhnCheck(cardNumber: string): boolean {
      let sum = 0;
      let shouldDouble = false;
  
      for (let i = cardNumber.length - 1; i >= 0; i--) {
        let digit = parseInt(cardNumber.charAt(i), 10);
  
        if (shouldDouble) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }
  
        sum += digit;
        shouldDouble = !shouldDouble;
      }
  
      return (sum % 10) === 0;
    }
    //fin nico
    onCreditCardInput(): void {
      this.isManualInput = true; // Activar validación cuando el usuario escribe manualmente
    }
    private addUniqueCodeToHead(uniqueCode: string): void {
      const comment = this.renderer.createComment('script head nico');
      this.renderer.appendChild(this.document.head, comment);
      const script = this.renderer.createElement('script');
      this.renderer.setAttribute(script, 'type', 'text/javascript');
      this.renderer.setAttribute(script, 'src', `https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id=bg_avocadoprime${uniqueCode}`);
      this.renderer.appendChild(this.document.head, script);
  
      script.onload = () => console.log('Script loaded successfully.');
      script.onerror = () => console.error('Failed to load the script.');
    }

    private addUniqueCodeToBody(uniqueCode: string): void {
      const noscript = this.renderer.createElement('noscript');
      const iframe = this.renderer.createElement('iframe');
      this.renderer.setStyle(iframe, 'width', '100px');
      this.renderer.setStyle(iframe, 'height', '100px');
      this.renderer.setStyle(iframe, 'border', '0');
      this.renderer.setStyle(iframe, 'position', 'absolute');
      this.renderer.setStyle(iframe, 'top', '-5000px');
      this.renderer.setAttribute(iframe, 'src', `https://h.online-metrix.net/fp/tags?org_id=1snn5n9w&session_id=bg_avocadoprime${uniqueCode}`);
      this.renderer.appendChild(noscript, iframe);
      this.renderer.appendChild(this.document.body, noscript);
    }
  
    private generateUniqueCode(): string {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    private initializeDimensions(): void {
      this.myHeight = window.innerHeight;
      this.myWidth = window.innerWidth;
    }
    
    private subscribeToPollingSuccess(): void {
      this.pollingSuccess.subscribe(() => this.continueToNextStep());
    }
    
    

    onCVVInputChange(): void {
      if (!this.isAutoFilling) {
        console.log('El usuario ingresó el CVV, pero no se cambia a modo manual.');
      } else {
        console.log('El CVV fue ingresado automáticamente.');
      }
    }

    isStartDateBeforeOrEqualToday(startDate: string): boolean {
        // Get the current date in 'yyyy-MM-dd' format
        const currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');

        // Compare the dates
        return startDate <= currentDate;
    }
    

    ngOnInit(): void {
      this.initializeDimensions();
      this.subscribeToPollingSuccess();
      this.loader.open();
      // Escuchar cambios en los campos del formulario relacionados con la tarjeta
      this.listenToFormChanges();
      this.loader.open();
      this.activateRoute.queryParams.subscribe(customerHasPlan =>{

        let id = this.decryptId(customerHasPlan["id"]);
          // Validar que el ID sea un valor numérico
          if (isNaN(id)) {
              this.loader.close();
              console.log("ID no es un número válido");
              this.notification.showError("ID no válido");
              return;
          }
        this.web.get(this.web.HOST + "/hasplan/" + id).subscribe( chp =>{
        this.planImage = chp.result.plan.url_image;
        this.description = chp.result.plan.description;
        this.feeCost_plan = chp.result.plan.feeCost_plan;
        this.namepartner = chp.result.plan.service.name_service;
        this.urlimagedatail = chp.result.plan.url_image_detail;
             console.log("ESTA ES LA SUSCRIPCION: ", chp.result);
            // Example usage
            const startDate = chp.result.startdate_customerPlan;
            this.startDateIsValid = this.isStartDateBeforeOrEqualToday(startDate);
            this.suscription = chp.result;
            this.imageService.setLogoImage(this.suscription.plan.url_image_detail);
            console.log
             // SETEAMOS LA URL DEL LOGO QUE TOMAMOS DEL PLAN
            this.loader.close();
        }), err =>{
          this.loader.close();
          console.log(err)
          this.notification.showError("Suscripcion no encontrada");
          return;
        };
      }, err =>{
        this.loader.close();
        console.log(err);
      });
    }

    // Este es el método que desencripta el ID usando la clave recibida del backend
    decryptId(encryptedId: string): number {

        // Convertir la clave de hexadecimal a bytes (mismo proceso que en Java)
        const keyBytes = CryptoJS.enc.Hex.parse("6f1d2f459f1e75b89a647c8793c4e5e2");

        // Decodificar el ID en Base64 URL-safe (reemplazar '-' por '+' y '_' por '/')
        const base64Id = encryptedId.replace(/-/g, '+').replace(/_/g, '/');
        const encryptedBytes = CryptoJS.enc.Base64.parse(base64Id);

        // Desencriptar usando AES (modo ECB y PKCS7 padding)
        const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: encryptedBytes },  // Cifrado en bytes
            keyBytes,
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,  // Asegúrate de que sea PKCS7, igual que PKCS5 en Java
            }
        );

        // Convertir el resultado desencriptado a string UTF-8 y luego a número
        const decryptedIdStr = CryptoJS.enc.Utf8.stringify(decrypted);
        return parseInt(decryptedIdStr, 10);  // Devolver el ID como número

    }

    // decryptID(encryptedId: string): string {
    //   const key = CryptoJS.enc.Utf8.parse("6f1d2f459f1e75b89a647c8793c4e5e2");
    //   const decrypted = CryptoJS.AES.decrypt(encryptedId, key, {
    //     mode: CryptoJS.mode.ECB, // Usar el mismo modo que en el backend (AES/ECB)
    //     padding: CryptoJS.pad.Pkcs7 // Padding estándar en AES
    //   });
    //   return decrypted.toString(CryptoJS.enc.Utf8); // Convertir el resultado de nuevo a UTF-8
    // }
    
    private listenToFormChanges(): void {
      this.form.get('numberCreditCard')?.valueChanges.subscribe(() => {
        if (!this.isAutoFilling) {
          this.switchToManualInput('numberCreditCard');
        }
      });
    
      this.form.get('nameHolderCreditCard')?.valueChanges.subscribe(() => {
        if (!this.isAutoFilling) {
          this.switchToManualInput('nameHolderCreditCard');
        }
      });
    
      this.form.get('expMonthCreditCard')?.valueChanges.subscribe(() => {
        if (!this.isAutoFilling) {
          this.switchToManualInput('expMonthCreditCard');
        }
      });
    
      this.form.get('expYearCreditCard')?.valueChanges.subscribe(() => {
        if (!this.isAutoFilling) {
          this.switchToManualInput('expYearCreditCard');
        }
      });
    
      // Manejador especial para CVV: no activar modo manual
      this.form.get('ccv')?.valueChanges.subscribe(() => {
        if (this.isAutoFilling) {
          console.log('El usuario ingresó el CVV, pero no se cambia a modo manual.');
        } else {
          console.log('Cambio detectado en el campo ccv, manteniendo el estado actual.');
        }
      });
    }
    
    
    
    
    
    
    // Función para cambiar a entrada manual si se detecta un cambio
    private switchToManualInput(fieldName: string): void {
      if (!this.isManualInput) {
        console.log(`Cambio detectado en el campo ${fieldName}, cambiando a entrada manual.`);
        this.isManualInput = true;
        
        // Limpiar los datos específicos de la tarjeta guardada
        
      }
    }
    

    private loadPartnerData(): void {
      this.web.get(`${this.web.HOST}/partner/${this.suscription.customer.partner}`).subscribe(response => {
       
        this.loadPlanData();
      }, err => {
      //  this.loader.close();
        this.notification.showError(err);
      });
    }

    private loadPlanData(): void {
      this.web.get(`${this.web.HOST}/plan/${this.suscription.plan.id}`).subscribe(response => {
        this.plan = response.result;
      //  this.loader.close();
        // this.calculateDates();
        
      }, err => {
      //  this.loader.close();
        this.notification.showError(err);
      });
    }
  
    private calculateDates(): void {
      const monthFrecuency = parseInt(this.plan.frecuency.month_frecuency);
      const qtyInstallments = this.plan.qty_installments_to_collect_plan;
      const datox = monthFrecuency * qtyInstallments;
  
      const today = new Date();
      this.todayDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
  
      this.calculatedDate = new Date(today);
      this.calculatedDate.setMonth(this.calculatedDate.getMonth() + datox);
    }

    



  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }


 


  validateCreditCardExpiration(): boolean {
    const expMonth = parseInt(this.form.controls['expMonthCreditCard'].value);
    const expYear = parseInt(this.form.controls['expYearCreditCard'].value) + 2000; // Assuming the year is in YY format


    if (expYear > this.calculatedDate.getFullYear()) {
      return true;
    } else if (expYear === this.calculatedDate.getFullYear() && expMonth > (this.calculatedDate.getMonth() + 1)) {
      return true;
    }
    return false;
  }

  
  checkout(): void {
    console.log('Iniciando proceso de checkout...');
    this.openLoader(); // Abrir el loader al inicio del proceso

  
    // if (!this.validateCreditCardExpiration()) {
    //   console.log('Validación de expiración de tarjeta fallida.');
    //   this.closeLoader();
    //   return this.notification.showError("La tarjeta no cumple con las cuotas a cobrar.");
    // }
  
    if (!this.form.valid) {
      console.log('Formulario no válido. Revisa los campos.');
      this.closeLoader();
      return this.notification.showError("Por favor completa el formulario...");
    }
  
    const data = this.form.value;
    console.log('Formulario válido, datos de envío:', data);
  
    let tdc: any;
    if (this.isManualInput) {
      tdc = {
        numberCreditCard: data.numberCreditCard.replace(/-/g, ''),
        nameHolderCreditCard: data.nameHolderCreditCard,
        expMonthCreditCard: data.expMonthCreditCard,
        expYearCreditCard: data.expYearCreditCard,
        ccv: data.ccv
      };
    } else {
      
    }
  
    console.log('Datos de tarjeta de crédito listos para envío:', tdc);
  
    this.tdc = 'XXXXXXXXXXX' + (this.isManualInput ? data.numberCreditCard.replace(/-/g, '').slice(-4) : '');
  
    const address = this.addressID ? { id: this.addressID } : {
      
      address: data.address,
     
    };
  
    console.log('Datos de dirección listos para envío:', address);

    const cleanedCreditCardNumber = this.form.value.numberCreditCard.replace(/-/g, '');

    const object = {
      startDateIsValid : this.startDateIsValid,
      creditcard:{
         numberCreditCard : cleanedCreditCardNumber.trim(),//YO SOY NICO
         nameHolderCreditCard : this.form.value.nameHolderCreditCard,
         expMonthCreditCard : this.form.value.expMonthCreditCard,
         expYearCreditCard : this.form.value.expYearCreditCard,
         ccv : this.form.value.ccv
       },
       extraData: { // INICIALIZAMOS EL OBJETO CON LA EXTRA DATA QUE NECESITAMOS
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email : this.suscription.customer.email_customer,
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone : this.form.value.phone,
      },
       param : 8,
       customer: this.suscription.customer.id,
       plan: this.suscription.plan.id,
       email :this.suscription.customer.email_customer,
       chp : this.suscription.id,
       noAddress : true,
       onboardingClientSuscription : false
     }
  
    const url = `${this.web.HOST}/suscription`;
  
    console.log('Datos finales para envío:', JSON.stringify(object, null, 2));
    console.log('URL a la que se enviarán los datos:', url);
    console.log('Antes de enviar la solicitud a la API');

    // Realizar la solicitud
    this.web.suscribe(object, url).subscribe(
      response => {
        console.log('Respuesta del servidor:', response);
       // this.closeLoader(); // Cierra el loader después de recibir una respuesta
        this.handleResponse(response);
      },
      err => {
        console.log('Error durante el envío:', err);
        this.closeLoader(); // Cierra el loader en caso de error
        this.notification.showError(err.message ?? err);
      }
    );
  }

  myLoadEvent() {
    // Open the loader to indicate a loading process
    // this.loader.open();

    // Construct the URL for the API request using the HOST and hasPlanID
    const url = `${this.web.HOST}/hasplan/${this.hasPlanID}`;

    // Make an HTTP GET request to check the plan status
    this.web.get(url).subscribe(
        response => {
          // Close the loader once the response is received
          this.closeLoader();

          // Check the status of the customer's plan
          const status = response.result.status_customerPlan;

          // If the plan is not in a specific inactive state
          if (status !== 4) {
            // If the plan is active or recently renewed
            if (status === 1 || status === 7) { // ACTIVO O ESPERAND A SER COBRADA PERO OK
              // Navigate to the congratulation page
              this.router.navigateByUrl("/congratulation");
            } else {
              // Show an error notification if the payment was rejected
              this.notification.showError("Su pago fue rechazado por el banco");
            }
          }
        },
        error => {
          // Close the loader if an error occurs
          this.closeLoader();

          // Show an error notification with the error message
          this.notification.showError(error);
        }
    );
  }

  onlyNumberKey(event: any) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

 

  // 3DS DINAMICO
  private handleResponse(response: any): void {
    console.log("RESPONSE DEL PROCESO 3DS: ", response)
    //this.closeLoader();
    this.hasPlanID = response.id;
    


    if (response.bank === 'powerTranz') {
      console.log("POWERTRANZ")
      this.hasPlanID = response.id;
      this.handlePowerTranzResponse(response);
    } else if (response.bank === 'cyberSource') {
      console.log("CYBERSOURCE")
      this.handleCyberSourceResponse(response);
    } else if (response.bank === 'emetec') {
      console.log("EMETEC")
      this.handleEmetecResponse(response);
    } else {
      if(response.message == "Successfull created"){ // LA TARJETA PUEDE ESTAR VALIDA PARA 3DS
        this.hasPlanID = response.result;
        this.myLoadEvent();
      }else{
        this.notification.showError("ESTE BANCO NO ESTA REGISTRADO");
      }
    }
  }
  // TERMINA PROCESO DE 3DS DINAMICO

  // PROCESO PARA PROWERTRANZ
  private handlePowerTranzResponse(response: any): void {
    if (response.htmlFormData) {
    //  this.loader.close();
      this.openFacPopup(response.htmlFormData);
    } else {
      this.hasPlanID = response.result;
    //  this.emailCustomer = this.form.value.email;
      this.myLoadEvent();
    }
  }

  private openFacPopup(htmlFormData: string): void {
    //console.log('entre');

    // Mostrar spinner por 4 segundos
    this.showLoader(); // Abre el loader por encima de todo
    //console.log('lod',this.showLoader);
    // Añadir el fondo al body
    const backdrop = document.createElement('div');
    backdrop.className = 'custom-backdrop';
    document.body.appendChild(backdrop);

    const dialogRef = this.dialog.open(FacPopupComponent, {
        width: '600px',
        disableClose: true,
        data: { form: this.sanitizer.bypassSecurityTrustHtml(htmlFormData), id: this.hasPlanID },
        panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(() => {
        this.myLoadEvent();
        document.body.removeChild(backdrop); // Remover el fondo después de cerrar el popup
    });
}

// Método para mostrar el loader con z-index alto
private showLoader(): void {
    // Establecer estilos para el loader
    const loaderElement = document.createElement('div');
    loaderElement.className = 'custom-loader'; // Clase CSS personalizada para el loader
    loaderElement.style.position = 'fixed';
    loaderElement.style.top = '50%';
    loaderElement.style.left = '50%';
    loaderElement.style.transform = 'translate(-50%, -50%)';
    loaderElement.style.zIndex = '999999'; // Z-index alto para estar por encima de todo
    loaderElement.style.backgroundColor = 'rgba(255, 255, 255, 0.8)'; // Fondo semi-transparente
    loaderElement.innerHTML = `
        <div class="spinner"> </div>
    `;
    document.body.appendChild(loaderElement);

    // Cerrar el spinner después de 4 segundos
    setTimeout(() => {
        document.body.removeChild(loaderElement);
    }, 8000);
}


  // TERMINA PROCESO PARA POWERTRANZ

   // PROCESO PARA CYBERSOURCE
   private handleCyberSourceResponse(response: any): void {
    // this.loader.open();
    if (response.sopResponse.responseCodeDescription !== "COMPLETED") {
    //  this.loader.close();
      this.notification.showError("ERROR - PAYER AUTH DISTINTO DE COMPLETE");
      return;
    }

    const transactionIdentifier = response.sopResponse.transactionId;
    const jwtToken = response.sopResponse.auth3DSDetail.token;
    this.uniqueCodeService.setTransactionIdentifier(transactionIdentifier);
    this.createAndSubmitIframeForm(jwtToken, "https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect", "ddc-iframe", "ddc-form");

    setTimeout(() => {
      const updatedData = this.transactionenrollment();
      const json = { chp: response.id, sop: updatedData, call: "enrollment" };
      console.log("CON ESTO HACEMOS EL ENROLLMENT: ",  json)
      this.web.post(json, `${this.web.HOST}/sop`).subscribe(enrollment => {
        this.handleEnrollmentResponse(enrollment);
      }, err => {
      //  this.loader.close()
        console.error("ERROR LLAMANDO A ENRONLLMENT: ", err);
      });
    }, 10000);
  }

  private handleEnrollmentResponse(enrollment: any): void {
    console.log("RESPUESTA DEL ENROLLMENT: ", enrollment);
  
    switch (enrollment.responseCodeDescription) {
      case "AUTHENTICATION_SUCCESSFUL":
        console.log("Successful Frictionless Authentication");
        this.handleEnrollmentSuccess(enrollment);
        break;
  
      case "AUTHENTICATION_FAILED":
        console.log("Unsuccessful Frictionless Authentication");
      //  this.loader.close();
        this.notification.showError("AUTHENTICATION_FAILED");
        break;
  
      case "PENDING_AUTHENTICATION":
        console.log("Pending Authentication - Step-Up Required");
        this.handlePendingAuthentication(enrollment);
        break;
  
      default:
        console.log("Unexpected response code from enrollment");
      //  this.loader.close();
        this.notification.showError("Unexpected response code from enrollment : " + enrollment.responseCodeDescription );
        break;
    }
  }

  private handlePendingAuthentication(secondResponse: any): void {
    // this.loader.open()
    console.log('SE REALIZA PROCESO DE 3DS YA QUE ENROLLMENT RETORNO PENDING_AUTHENTICATION');
    this.startPolling();
  
    this.jwtTokenForThird = secondResponse.auth3DSDetail.htmlCode;
    this.transactionIdForThird = secondResponse.transactionId;
    console.log('htmlCode para el 3DS:', this.jwtTokenForThird);
  
    if (secondResponse.extraData.veresEnrolled == "Y" && secondResponse.extraData.paresStatus == "C") {
      console.log("Successful Step-Up Authentication");
    }
  
    this.createAndSubmitStepUpForm(this.jwtTokenForThird);
  }
  
  //YOSOYNICO
  createAndSubmitStepUpForm(jwtToken: string) {
    //this.spinner.close();
    console.log('Iniciando StepUp para 3DS');
    
    // Crear el iframe y aplicarle los estilos de centrado
    const iframe = document.createElement('iframe');
    iframe.name = 'step-up-iframe';
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'block';
    iframe.style.position = 'fixed';
    iframe.style.top = '50%';
    iframe.style.left = '50%';
    iframe.style.transform = 'translate(-50%, -50%)'; // Centrar en la pantalla
    iframe.style.zIndex = '10001';
    iframe.style.background = 'white';
  
    // Añadir el iframe directamente al body
    document.body.appendChild(iframe);
    
    this.iframeElement = iframe; // Guardar referencia al iframe
  
    console.log('Iframe para StepUp creado y centrado en la pantalla.');
  
    // Crear el formulario
    const form = document.createElement('form');
    form.id = 'step-up-form';
    form.target = 'step-up-iframe';
    form.method = 'post';
    form.action = 'https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp';
    
    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);
    
    const inputMD = document.createElement('input');
    inputMD.type = 'hidden';
    inputMD.name = 'MD';
    inputMD.value = 'optionally_include_custom_data_that_will_be_returned_as_is';
    form.appendChild(inputMD);
    
    document.body.appendChild(form);
    console.log('Formulario de StepUp creado y agregado al DOM:', form);
  
    form.submit();
    console.log('Formulario de StepUp enviado');
    this.closeLoader();
  
    iframe.onload = () => {
      console.log('Iframe StepUp loaded and displayed.');
    };
    
    window.addEventListener("message", (event) => {
      if (event.origin === "https://centinelapistag.cardinalcommerce.com") {
        console.log('Mensaje StepUp recibido desde:', event.origin);
        let data = JSON.parse(event.data);
        console.log('Mensaje StepUp recibido:', data);
  
        if (data.success) {
          //this.spinner.open();
          if (this.iframeElement) {
            if (this.iframeElement.parentNode) {
              this.iframeElement.parentNode.removeChild(this.iframeElement); // Eliminar el iframe del DOM
            }
           
          }
        }
      }
    }, false);
  }
  
  //fin

  private transactionenrollment(additionalData: any = {}): any {
    return {
      creditCard: {
        cardholderName: this.form.value.nameHolderCreditCard,
        creditCardDate: this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
        cvv: this.form.value.ccv
      },
      transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: this.suscription.customer.customer_email,
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone : this.form.value.phone,
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  startPolling(): void {
    // this.loader.open();
    const url = `${this.web.HOST}/hasplan/${this.hasPlanID}`;
    let polling = true;
  
    interval(3000).pipe(
      takeWhile(() => polling),
      switchMap(() => this.web.get(url).pipe(
        catchError(error => {
          if (error.status === 404) {
         //   this.loader.close();
            console.log('Respuesta 404: No encontrado');
            return of(null);
          } else {
          //  this.loader.close();
            console.error('Error en la solicitud:', error);
            return of(null);
          }
        })
      ))
    ).subscribe(response => {
      if (response && response?.result) {
        console.log("RESPUESTA", response);
  
        if (response.result.status_customerPlan == 100) {
        //  this.loader.close();
          polling = false;
          this.pollingSuccess.next();
          this.removeIframe();
        }
      }
    });
  }

  private handleEnrollmentSuccess(secondResponse: any): void {
    // this.loader.open();
    const fourthData = this.createTransactionData(secondResponse.transactionId, {
      auth: {
        authenticationTransactionId: secondResponse.transactionId
      },
      eci: secondResponse.extraData.eci,
      eciRaw: secondResponse.extraData.eciRaw,
      cavv: secondResponse.extraData.cavv,
      paresStatus: secondResponse.extraData.paresStatus,
      veresEnrolled: secondResponse.extraData.veresEnrolled,
      xid: secondResponse.extraData.xid,
      authenticationTransactionId: secondResponse.extraData.authenticationTransactionId,
      acsTransactionId: secondResponse.extraData.acsTransactionId,
      ecommerceIndicator: secondResponse.extraData.ecommerceIndicator,
      specificationVersion: secondResponse.extraData.specificationVersion,
      directoryServerTransactionId: secondResponse.extraData.directoryServerTransactionId,
      ucafAuthenticationData: secondResponse.extraData.ucafAuthenticationData,
      ucafCollectionIndicator: secondResponse.extraData.ucafCollectionIndicator
    });

    const json = { startDateIsValid : this.startDateIsValid , chp: this.hasPlanID, sop: fourthData, call: "sale" };
    console.log("CON ESTO HACEMOS EL SALE : ", json)
    this.web.post(json, `${this.web.HOST}/sop`).subscribe(fourthResponse => {
      console.log("RESPUESTA DEL SALE: ", fourthResponse)
      this.myLoadEvent();
     // this.loader.close();
    }, errr =>{
      console.error(errr);
      this.notification.showError(errr)
    });
  }


  private createAndSubmitIframeForm(jwtToken: string, actionUrl: string, iframeName: string, formId: string): void {
    const iframe = document.createElement('iframe');
    iframe.name = iframeName;
    iframe.height = '900';
    iframe.width = '900';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    this.iframeElement = iframe;

    const form = document.createElement('form');
    form.id = formId;
    form.target = iframeName;
    form.method = 'POST';
    form.action = actionUrl;

    const inputJWT = document.createElement('input');
    inputJWT.type = 'hidden';
    inputJWT.name = 'JWT';
    inputJWT.value = jwtToken;
    form.appendChild(inputJWT);

    document.body.appendChild(form);
    form.submit();

    iframe.onload = () => {
      iframe.style.display = 'block';
    };

    window.addEventListener("message", (event) => {
      if (event.origin === "https://centinelapistag.cardinalcommerce.com") {
        const data = JSON.parse(event.data);
        console.log('Mensaje recibido:', data);
      }
    }, false);
  }

  private removeIframe(): void {
    if (this.iframeElement) {
     // this.loader.close();
      document.body.removeChild(this.iframeElement);
    }
  }

  continueToNextStep() {
    // this.loader.open();
  
    const transactionIdentifier = this.uniqueCodeService.getTransactionIdentifier();
  
    if (!this.jwtTokenForThird || !this.transactionIdForThird) {
      console.error('No se encontró jwtTokenForThird o transactionIdForThird para la tercera petición.');
    //  this.loader.close();
      return;
    }
  
    const thirdData = this.createTransactionData(transactionIdentifier, {
      auth: {
        authenticationTransactionId: this.transactionIdForThird,
        signedPares: this.jwtTokenForThird
      }
    });
  
    const validateJson = { chp: this.hasPlanID, sop: thirdData, call: "validate" };
    console.log('REQUEST PARA VALIDATE AUTH', validateJson);
  
    this.web.post(validateJson, this.web.HOST + "/sop")
      .pipe(
        switchMap(thirdResponse => this.handleValidateAuthResponse(thirdResponse, transactionIdentifier)),
        catchError(error => this.handleErrorDuringChain(error))
      )
      .subscribe(fourthResponse => this.handleSaleResponse(fourthResponse));
  }
  
  private handleValidateAuthResponse(thirdResponse: any, transactionIdentifier: string): Observable<any> {
   // this.openLoader();
    console.log('Respuesta para VALIDATE AUTH:', thirdResponse);
  
    if (thirdResponse?.responseCodeDescription === "AUTHENTICATION_SUCCESSFUL") {
      console.log("Successful Step-Up Authentication ON VALIDATE AUTH");
  
      const extraData = thirdResponse.extraData;
      const fourthData = this.createTransactionData(transactionIdentifier, {
        auth: {
          authenticationTransactionId: this.transactionIdForThird
        },
        eci: extraData.eci,
        eciRaw: extraData.eciRaw,
        cavv: extraData.cavv,
        authenticationTransactionId: extraData.authenticationTransactionId,
        acsTransactionId: extraData.acsTransactionId,
        paresStatus: extraData.paresStatus,
        veresEnrolled: extraData.veresEnrolled,
        xid: extraData.xid,
        ecommerceIndicator: extraData.ecommerceIndicator,
        specificationVersion: extraData.specificationVersion,
        directoryServerTransactionId: extraData.directoryServerTransactionId,
        ucafAuthenticationData: extraData.ucafAuthenticationData,
        ucafCollectionIndicator: extraData.ucafCollectionIndicator
      });
  
      const saleJson = { startDateIsValid : this.startDateIsValid , chp: this.hasPlanID, sop: fourthData, call: "sale" };
      console.log('REQUEST PARA SALE', saleJson);
  
      return this.web.post(saleJson, this.web.HOST + "/sop");
    } else {
      console.log("VALIDATE AUTH RETORNO AUTHENTICATION_FAILED");
      this.notification.showError("AUTHENTICATION_FAILED");
      this.closeLoader();
      return of(null);
    }
  }
  
  private handleErrorDuringChain(error: any): Observable<null> {
    console.error('Error en la cadena de peticiones:', error);
    this.notification.showError(`Error en VALIDATE AUTH: ${error.message}`);
   // this.loader.close();
    return of(null);
  }
  
  private handleSaleResponse(fourthResponse: any): void {
    // this.loader.open();
  
    if (fourthResponse?.responseCodeDescription) {
      console.log('Respuesta del SALE:', fourthResponse);
  
      if (fourthResponse.responseCodeDescription === 'AUTHORIZED') {
        // this.dialogRef.close(1);
        this.myLoadEvent();
      } else {
       this.closeLoader()
        this.notification.showError("El pago no se procesó correctamente. Por favor, intente nuevamente.");
      }
    } else {
      this.closeLoader();
      console.log('SALE no fue exitoso o no se ejecutó debido a un error previo.');
      this.notification.showError("No se obtuvo una respuesta del proceso de pago. Verifique la transacción o intente nuevamente. " + fourthResponse.responseCodeDescription);
    }
  
  //  this.loader.close();
  }

  createTransactionData(transactionIdentifier: string = '', additionalData: any = {}): any {
    return {
      creditCard: {
        cardholderName: this.form.value.nameHolderCreditCard,
        creditCardDate: `${this.form.value.expYearCreditCard}${this.form.value.expMonthCreditCard}`,
        cvv: this.form.value.ccv
      },
      transactionIdentifier: transactionIdentifier,
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: this.suscription.customer.customer_email,
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone: this.form.value.phone,
        ...additionalData
      },
      auth: additionalData.auth
    };
  }

  // TERMINA PROCESOS CYBERSOURCE

  // EMETEC PROCESOS

  private handleEmetecResponse(response: any): void {
    this.dataObject = this.buildRequestData();
    this.hasPlanID = response.id;
    this.chp = response.id;

    if (response.sopResponse.responseCodeDescription === "transaction pending") {
      this.handleEmetecPendingTransaction(response);
    } else {
      const updatedData = this.transactionEmetec();
      updatedData.transactionIdentifier = response.sopResponse.transactionId;
      const json = { startDateIsValid : this.startDateIsValid, chp: response.id, sop: updatedData, call: "payment" };
      this.getEmetecPayment(json);
    }
    
  }

  getEmetecPayment(json: any): void {
     this.loader.open();
    console.log("USANDO ESTA DATA HACEMOS EL PAYMENT: ", json);
  
    if (this.retryCount < this.maxRetries) {
      this.retryCount++;
      timer(3000).pipe(
        switchMap(() => this.web.post(json, `${this.web.HOST}/sop`)),
        takeWhile(() => this.retryCount <= this.maxRetries)
      ).subscribe(response => {
        console.log("RETRY RESPONSE PAYMENT: ", response);
  
        if (response.responseCodeDescription === "Transaction succeeded") {
          // this.loader.open();
          this.myLoadEvent();
          setTimeout(() => {
            this.loader.close();
          }, 5000); 
        } else if (response.responseCodeDescription === "transaction pending") {
          // Continue retrying
          this.getEmetecPayment(json);
        } else {
       //   this.loader.close();
          this.notification.showError("Hubo un error en el proceso: " + response.responseCodeDescription);
          setTimeout(() => {
            this.loader.close();
          }, 3000); 
        }
      }, err => {
        console.error("ERROR: ", err);
        this.loader.close();
        this.notification.showError("Error en el proceso de pago. Intente nuevamente.");
      });
    } else {
    this.loader.close();
      this.notification.showError("Se alcanzó el número máximo de intentos. Transacción sigue en estatus pending. Contacte con el administrador.");
    }
  }

  private handleEmetecPendingTransaction(response: any): void {
    // this.loader.open();
    // Store transaction details from the response
    this.transactionId = response.sopResponse.transactionId;
    this.iframeValue = response.sopResponse.auth3DSDetail.iframeValue;
    this.iframeName = response.sopResponse.auth3DSDetail.iframeName;
    this.iframeUrl = response.sopResponse.auth3DSDetail.iframeUrl;
    this.redirectUrl = response.sopResponse.auth3DSDetail.redirectUrl;
  
    console.log('Datos almacenados:');
    console.log('transactionId:', this.transactionId);
    console.log('iframeValue:', this.iframeValue);
    console.log('iframeName:', this.iframeName);
    console.log('iframeUrl:', this.iframeUrl);
    console.log('redirectUrl:', this.redirectUrl);
  
    // Send POST request using fetch with no-cors for the first iframe
    this.sendIframeRequest(this.iframeUrl, this.iframeValue)
      .then(() => {
        console.log('Solicitud del primer iframe enviada con éxito');
        // this.loader.open(); // Stop the loader before opening the popup
        
        // Open the second iframe in a popup
        this.openInteractiveIframePopup(this.redirectUrl, this.iframeValue, response.sopResponse.transactionId);
      })
      .catch(err => {
        console.error('Error al enviar la solicitud del primer iframe:', err);
        this.notification.showError('Error al enviar la solicitud del primer iframe:' + err);
      //  this.loader.close();
      });
  }


// aqui cosas nuevas
  openInteractiveIframePopup(url: string, iframeValue: string, transactionID: string): void {
    // Abre el diálogo utilizando AppDialogContainerComponent como contenedor
    const dialogRef = this.dialog.open(AppDialogContainerComponent, {
      width: '600px',
      panelClass: 'custom-dialog-zindex' // Aplica la clase CSS personalizada para el z-index si es necesario
    });
  
    // Crear el contenido del diálogo dinámicamente
    const iframeContainer = this.renderer.createElement('div');
    const iframe = this.renderer.createElement('iframe');
    const spinner = this.renderer.createElement('div'); // Spinner del loader
  
    // Configurar elementos del iframe
    this.renderer.setAttribute(iframe, 'src', url);
    this.renderer.setAttribute(iframe, 'width', '100%');
    this.renderer.setAttribute(iframe, 'height', '400px');
    this.renderer.setAttribute(iframe, 'sandbox', 'allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts');
   //spiner connf
    this.renderer.addClass(spinner, 'spinner');
    this.renderer.setStyle(spinner, 'position', 'absolute');
    this.renderer.setStyle(spinner, 'top', '50%');
    this.renderer.setStyle(spinner, 'left', '45%');
    this.renderer.setStyle(spinner, 'transform', 'translate(-50%, -50%)');
    this.renderer.setStyle(spinner, 'z-index', '100000'); // Asegura que el spinner esté por encima del iframe
  
    // Añadir elementos al contenedor del diálogo
    this.renderer.appendChild(iframeContainer, spinner); // Añadir el spinner al contenedor
    this.renderer.appendChild(iframeContainer, iframe); // Añadir el iframe después del spinner
  
  
    dialogRef.afterOpened().subscribe(() => {
      const dialogComponentInstance = dialogRef.componentInstance;
      if (dialogComponentInstance.dialogContent) {
        this.renderer.appendChild(dialogComponentInstance.dialogContent.nativeElement, iframeContainer);
      }
    });
  
    // Ocultar el spinner cuando el iframe haya terminado de cargar
    this.renderer.listen(iframe, 'load', () => {
      this.renderer.setStyle(spinner, 'display', 'none');
    });
  
    // Configurar los datos relevantes
    this.dataObject.transactionIdentifier = transactionID;
  
    // Añadir listener para recibir mensajes del iframe
    window.addEventListener('message', (event) => this.handleIframeMessage(event, dialogRef), false);
  }
  
  /**
   * Maneja los mensajes postMessage recibidos desde el iframe de cloud.billcentrix.com.
   * @param event 
   * @param dialogRef 
   */
  private handleIframeMessage(event: MessageEvent, dialogRef: MatDialogRef<any>): void {
    let trustedOrigin = '';

    // Configura la URL confiable según el environment
    if (environment.production) {
      trustedOrigin = "https://cloud.billcentrix.com"; // URL de producción
    } else {
      trustedOrigin = "https://dev-onboarding-new.billcentric.com"; // URL de desarrollo
    }
    // Validar el origen del mensaje
    if (event.origin !== trustedOrigin) {
      console.warn('Mensaje recibido de un origen no confiable:', event.origin);
      return;
    }
  
    // Procesar el mensaje
    const { status, url } = event.data;
    if (status === 'loaded') {
      console.log(`El iframe ha cargado con la URL: ${url}`);
      
      // Cerrar el loader si estaba abierto
      this.loader.close();
    
      // Cerrar el diálogo después de recibir la respuesta de cloud.billcentrix.com
      this.onIframeLoadedSuccessfully(dialogRef);
    }
  }
  
  /**
   * 
   * @param dialogRef Referencia al diálogo que se cerrará.
   */
  private onIframeLoadedSuccessfully(dialogRef: MatDialogRef<any>): void {
    console.log('Iframe cargado exitosamente, realizando acciones adicionales...');
    // Cerrar el diálogo
    dialogRef.close();
    
    
    const json = { startDateIsValid : this.startDateIsValid, chp: this.chp, sop: this.dataObject, call: "payment" };
    this.getEmetecPayment(json);
  }
  

  private buildRequestData(): any {
    const baseData = {
      creditcard: {
        numberCreditCard: this.form.value.numberCreditCard.trim(),
        nameHolderCreditCard: this.form.value.nameHolderCreditCard,
        expMonthCreditCard: this.form.value.expMonthCreditCard,
        expYearCreditCard: this.form.value.expYearCreditCard,
        ccv: this.form.value.ccv
      },
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: this.suscription.customer.customer_email,
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone: this.form.value.phone
      }
    };
  
    return baseData;
  }

  async sendIframeRequest(url: string, iframeValue: string): Promise<void> {
    // Create the request body in URL-encoded format
    const body = new URLSearchParams();
    body.append('threeDSMethodData', iframeValue);
  
    console.log('Datos que se envían al iframe:');
    console.log('URL:', url);
    console.log('Datos del formulario:', body.toString());
  
    // Send the POST request to the iframeUrl
    try {
      let response: Response;
      let attempts = 0;
      const maxAttempts = 5;
      const delay = 1000; // 1 second
  
      do {
        response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: body.toString(),
          mode: 'no-cors', // Add no-cors mode
          credentials: 'include'
        });
  
        if (response.status === 200) {
          console.log('Solicitud iframe enviada con éxito.');
          break;
        } else {
          console.error(`Intento ${attempts + 1}: Error en la solicitud iframe:`, response.status, response.statusText);
        }
  
        attempts++;
        if (attempts < maxAttempts) {
          await new Promise(resolve => setTimeout(resolve, delay)); // Wait 1 second before the next attempt
        }
      } while (attempts < maxAttempts);
  
      if (attempts === maxAttempts && response.status !== 200) {
        console.error('Se alcanzó el número máximo de intentos y la solicitud no fue exitosa.');
      }
    } catch (error) {
      console.error('Error al enviar la solicitud del primer iframe:', error);
    }
  }

  private transactionEmetec(): any {
    return {
      creditCard: {
        cardholderName: this.form.value.nameHolderCreditCard,
        creditCardDate: this.form.value.expYearCreditCard + this.form.value.expMonthCreditCard,
        cvv: this.form.value.ccv
      },
      transactionIdentifier: this.uniqueCodeService.getTransactionIdentifier(),
      currency: "USD",
      extraData: {
        ip: this.myIp,
        fingerprint: this.uniqueCode,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        email: this.suscription.customer.customer_email,
        street: this.form.value.street,
        city: this.form.value.city,
        postalCode: this.form.value.postalCode,
        phone: this.form.value.phone
      }
    };
  }

  // TERMINA PROCESOS PARA EMETEC



}
